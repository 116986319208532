/* tslint:disable */
/* eslint-disable */
/**
 * Dataland Backend API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../../../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../../../../../base';
// @ts-ignore
import type { CompanyAssociatedDataPathwaysToParisData } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { DataAndMetaInformationPathwaysToParisData } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { DataMetaInformation } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { ErrorResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
/**
 * P2pDataControllerApi - axios parameter creator
 * @export
 */
export const P2pDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {boolean} [showOnlyActive] 
         * @param {string} [reportingPeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyP2pData: async (companyId: string, showOnlyActive?: boolean, reportingPeriod?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanyP2pData', 'companyId', companyId)
            const localVarPath = `/data/p2p/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (showOnlyActive !== undefined) {
                localVarQueryParameter['showOnlyActive'] = showOnlyActive;
            }

            if (reportingPeriod !== undefined) {
                localVarQueryParameter['reportingPeriod'] = reportingPeriod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAssociatedP2pData: async (dataId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataId' is not null or undefined
            assertParamExists('getCompanyAssociatedP2pData', 'dataId', dataId)
            const localVarPath = `/data/p2p/{dataId}`
                .replace(`{${"dataId"}}`, encodeURIComponent(String(dataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyAssociatedDataPathwaysToParisData} companyAssociatedDataPathwaysToParisData 
         * @param {boolean} [bypassQa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyAssociatedP2pData: async (companyAssociatedDataPathwaysToParisData: CompanyAssociatedDataPathwaysToParisData, bypassQa?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyAssociatedDataPathwaysToParisData' is not null or undefined
            assertParamExists('postCompanyAssociatedP2pData', 'companyAssociatedDataPathwaysToParisData', companyAssociatedDataPathwaysToParisData)
            const localVarPath = `/data/p2p`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (bypassQa !== undefined) {
                localVarQueryParameter['bypassQa'] = bypassQa;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyAssociatedDataPathwaysToParisData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * P2pDataControllerApi - functional programming interface
 * @export
 */
export const P2pDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = P2pDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {boolean} [showOnlyActive] 
         * @param {string} [reportingPeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanyP2pData(companyId: string, showOnlyActive?: boolean, reportingPeriod?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataAndMetaInformationPathwaysToParisData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanyP2pData(companyId, showOnlyActive, reportingPeriod, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['P2pDataControllerApi.getAllCompanyP2pData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyAssociatedP2pData(dataId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyAssociatedDataPathwaysToParisData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyAssociatedP2pData(dataId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['P2pDataControllerApi.getCompanyAssociatedP2pData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CompanyAssociatedDataPathwaysToParisData} companyAssociatedDataPathwaysToParisData 
         * @param {boolean} [bypassQa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyAssociatedP2pData(companyAssociatedDataPathwaysToParisData: CompanyAssociatedDataPathwaysToParisData, bypassQa?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataMetaInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyAssociatedP2pData(companyAssociatedDataPathwaysToParisData, bypassQa, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['P2pDataControllerApi.postCompanyAssociatedP2pData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * P2pDataControllerApi - factory interface
 * @export
 */
export const P2pDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = P2pDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {boolean} [showOnlyActive] 
         * @param {string} [reportingPeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyP2pData(companyId: string, showOnlyActive?: boolean, reportingPeriod?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataAndMetaInformationPathwaysToParisData>> {
            return localVarFp.getAllCompanyP2pData(companyId, showOnlyActive, reportingPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAssociatedP2pData(dataId: string, options?: RawAxiosRequestConfig): AxiosPromise<CompanyAssociatedDataPathwaysToParisData> {
            return localVarFp.getCompanyAssociatedP2pData(dataId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyAssociatedDataPathwaysToParisData} companyAssociatedDataPathwaysToParisData 
         * @param {boolean} [bypassQa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyAssociatedP2pData(companyAssociatedDataPathwaysToParisData: CompanyAssociatedDataPathwaysToParisData, bypassQa?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<DataMetaInformation> {
            return localVarFp.postCompanyAssociatedP2pData(companyAssociatedDataPathwaysToParisData, bypassQa, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * P2pDataControllerApi - interface
 * @export
 * @interface P2pDataControllerApi
 */
export interface P2pDataControllerApiInterface {
    /**
     * 
     * @param {string} companyId 
     * @param {boolean} [showOnlyActive] 
     * @param {string} [reportingPeriod] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2pDataControllerApiInterface
     */
    getAllCompanyP2pData(companyId: string, showOnlyActive?: boolean, reportingPeriod?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataAndMetaInformationPathwaysToParisData>>;

    /**
     * 
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2pDataControllerApiInterface
     */
    getCompanyAssociatedP2pData(dataId: string, options?: RawAxiosRequestConfig): AxiosPromise<CompanyAssociatedDataPathwaysToParisData>;

    /**
     * 
     * @param {CompanyAssociatedDataPathwaysToParisData} companyAssociatedDataPathwaysToParisData 
     * @param {boolean} [bypassQa] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2pDataControllerApiInterface
     */
    postCompanyAssociatedP2pData(companyAssociatedDataPathwaysToParisData: CompanyAssociatedDataPathwaysToParisData, bypassQa?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<DataMetaInformation>;

}

/**
 * P2pDataControllerApi - object-oriented interface
 * @export
 * @class P2pDataControllerApi
 * @extends {BaseAPI}
 */
export class P2pDataControllerApi extends BaseAPI implements P2pDataControllerApiInterface {
    /**
     * 
     * @param {string} companyId 
     * @param {boolean} [showOnlyActive] 
     * @param {string} [reportingPeriod] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2pDataControllerApi
     */
    public getAllCompanyP2pData(companyId: string, showOnlyActive?: boolean, reportingPeriod?: string, options?: RawAxiosRequestConfig) {
        return P2pDataControllerApiFp(this.configuration).getAllCompanyP2pData(companyId, showOnlyActive, reportingPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2pDataControllerApi
     */
    public getCompanyAssociatedP2pData(dataId: string, options?: RawAxiosRequestConfig) {
        return P2pDataControllerApiFp(this.configuration).getCompanyAssociatedP2pData(dataId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyAssociatedDataPathwaysToParisData} companyAssociatedDataPathwaysToParisData 
     * @param {boolean} [bypassQa] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2pDataControllerApi
     */
    public postCompanyAssociatedP2pData(companyAssociatedDataPathwaysToParisData: CompanyAssociatedDataPathwaysToParisData, bypassQa?: boolean, options?: RawAxiosRequestConfig) {
        return P2pDataControllerApiFp(this.configuration).postCompanyAssociatedP2pData(companyAssociatedDataPathwaysToParisData, bypassQa, options).then((request) => request(this.axios, this.basePath));
    }
}

