/* tslint:disable */
/* eslint-disable */
/**
 * Dataland Document Manager API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../../../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../../../../../base';
// @ts-ignore
import type { DocumentUploadResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/documentmanager/model';
// @ts-ignore
import type { ErrorResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/documentmanager/model';
/**
 * DocumentControllerApi - axios parameter creator
 * @export
 */
export const DocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check for a given document ID (hash) if the document already exists in the database.
         * @summary Check if a document exists.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDocument: async (documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('checkDocument', 'documentId', documentId)
            const localVarPath = `/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receive a document by its ID from internal storage.
         * @summary Receive a document.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocument', 'documentId', documentId)
            const localVarPath = `/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a document and receive meta information
         * @summary Upload a document.
         * @param {File} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocument: async (document: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document' is not null or undefined
            assertParamExists('postDocument', 'document', document)
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


            if (document !== undefined) { 
                localVarFormParams.append('document', document as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentControllerApi - functional programming interface
 * @export
 */
export const DocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Check for a given document ID (hash) if the document already exists in the database.
         * @summary Check if a document exists.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkDocument(documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkDocument(documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.checkDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Receive a document by its ID from internal storage.
         * @summary Receive a document.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.getDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upload a document and receive meta information
         * @summary Upload a document.
         * @param {File} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDocument(document: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDocument(document, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.postDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentControllerApi - factory interface
 * @export
 */
export const DocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentControllerApiFp(configuration)
    return {
        /**
         * Check for a given document ID (hash) if the document already exists in the database.
         * @summary Check if a document exists.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDocument(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.checkDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Receive a document by its ID from internal storage.
         * @summary Receive a document.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a document and receive meta information
         * @summary Upload a document.
         * @param {File} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocument(document: File, options?: RawAxiosRequestConfig): AxiosPromise<DocumentUploadResponse> {
            return localVarFp.postDocument(document, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentControllerApi - interface
 * @export
 * @interface DocumentControllerApi
 */
export interface DocumentControllerApiInterface {
    /**
     * Check for a given document ID (hash) if the document already exists in the database.
     * @summary Check if a document exists.
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApiInterface
     */
    checkDocument(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receive a document by its ID from internal storage.
     * @summary Receive a document.
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApiInterface
     */
    getDocument(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

    /**
     * Upload a document and receive meta information
     * @summary Upload a document.
     * @param {File} document 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApiInterface
     */
    postDocument(document: File, options?: RawAxiosRequestConfig): AxiosPromise<DocumentUploadResponse>;

}

/**
 * DocumentControllerApi - object-oriented interface
 * @export
 * @class DocumentControllerApi
 * @extends {BaseAPI}
 */
export class DocumentControllerApi extends BaseAPI implements DocumentControllerApiInterface {
    /**
     * Check for a given document ID (hash) if the document already exists in the database.
     * @summary Check if a document exists.
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public checkDocument(documentId: string, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).checkDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Receive a document by its ID from internal storage.
     * @summary Receive a document.
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getDocument(documentId: string, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a document and receive meta information
     * @summary Upload a document.
     * @param {File} document 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public postDocument(document: File, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).postDocument(document, options).then((request) => request(this.axios, this.basePath));
    }
}

