/* tslint:disable */
/* eslint-disable */
/**
 * Dataland Community Manager API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../../../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../../../../../base';
// @ts-ignore
import type { CompanyRole } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { CompanyRoleAssignment } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { ErrorResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
/**
 * CompanyRolesControllerApi - axios parameter creator
 * @export
 */
export const CompanyRolesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The company role for the specified company is being assigned to the user. Endpoint accessible to all Dataland-Admins and some Company-Role-Assignees of the company, based on the company role that shall be assigned.
         * @summary Assign company role for the company to the user.
         * @param {CompanyRole} role 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCompanyRole: async (role: CompanyRole, companyId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('assignCompanyRole', 'role', role)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('assignCompanyRole', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('assignCompanyRole', 'userId', userId)
            const localVarPath = `/company-role-assignments/{role}/{companyId}/{userId}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get company role assignments that match the provided filters. Endpoint fully accessible to all Dataland-Admins. Company-Role-Assignees can access the endpoint if companyId-filter is set to their company. Any Dataland-user can access the endpoint if the userId-filter is set to their userId.
         * @summary Get company role assignments that match the provided filters.
         * @param {CompanyRole} [role] 
         * @param {string} [companyId] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyRoleAssignments: async (role?: CompanyRole, companyId?: string, userId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company-role-assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates if at least one company owner exists for the specified company. Endpoint accessible to all Dataland users.
         * @summary Validate existence of company ownership for the company.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasCompanyAtLeastOneOwner: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('hasCompanyAtLeastOneOwner', 'companyId', companyId)
            const localVarPath = `/company-ownership/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks whether the company role for the company is assigned to the user. Endpoint accessible to all Dataland-Admins, for all users that check for their own userId, and all Company-Role-Assignees of the company.
         * @summary Validate company role for company and user.
         * @param {CompanyRole} role 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserCompanyRole: async (role: CompanyRole, companyId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('hasUserCompanyRole', 'role', role)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('hasUserCompanyRole', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('hasUserCompanyRole', 'userId', userId)
            const localVarPath = `/company-role-assignments/{role}/{companyId}/{userId}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request company ownership for the company on Dataland. Endpoint accessible to all Dataland users.
         * @summary Request company ownership for the company.
         * @param {string} companyId 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOwnershipRequest: async (companyId: string, comment?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompanyOwnershipRequest', 'companyId', companyId)
            const localVarPath = `/company-ownership/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The company role for the specified company is being removed from the user. Endpoint accessible to all Dataland-Admins and some Company-Role-Assignees of the company, based on the company role that shall be removed.
         * @summary Remove company role for the company from the user.
         * @param {CompanyRole} role 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCompanyRole: async (role: CompanyRole, companyId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('removeCompanyRole', 'role', role)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('removeCompanyRole', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeCompanyRole', 'userId', userId)
            const localVarPath = `/company-role-assignments/{role}/{companyId}/{userId}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyRolesControllerApi - functional programming interface
 * @export
 */
export const CompanyRolesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyRolesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * The company role for the specified company is being assigned to the user. Endpoint accessible to all Dataland-Admins and some Company-Role-Assignees of the company, based on the company role that shall be assigned.
         * @summary Assign company role for the company to the user.
         * @param {CompanyRole} role 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyRoleAssignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignCompanyRole(role, companyId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyRolesControllerApi.assignCompanyRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get company role assignments that match the provided filters. Endpoint fully accessible to all Dataland-Admins. Company-Role-Assignees can access the endpoint if companyId-filter is set to their company. Any Dataland-user can access the endpoint if the userId-filter is set to their userId.
         * @summary Get company role assignments that match the provided filters.
         * @param {CompanyRole} [role] 
         * @param {string} [companyId] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyRoleAssignments(role?: CompanyRole, companyId?: string, userId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyRoleAssignment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyRoleAssignments(role, companyId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyRolesControllerApi.getCompanyRoleAssignments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Validates if at least one company owner exists for the specified company. Endpoint accessible to all Dataland users.
         * @summary Validate existence of company ownership for the company.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasCompanyAtLeastOneOwner(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasCompanyAtLeastOneOwner(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyRolesControllerApi.hasCompanyAtLeastOneOwner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Checks whether the company role for the company is assigned to the user. Endpoint accessible to all Dataland-Admins, for all users that check for their own userId, and all Company-Role-Assignees of the company.
         * @summary Validate company role for company and user.
         * @param {CompanyRole} role 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasUserCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasUserCompanyRole(role, companyId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyRolesControllerApi.hasUserCompanyRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Request company ownership for the company on Dataland. Endpoint accessible to all Dataland users.
         * @summary Request company ownership for the company.
         * @param {string} companyId 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyOwnershipRequest(companyId: string, comment?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyOwnershipRequest(companyId, comment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyRolesControllerApi.postCompanyOwnershipRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The company role for the specified company is being removed from the user. Endpoint accessible to all Dataland-Admins and some Company-Role-Assignees of the company, based on the company role that shall be removed.
         * @summary Remove company role for the company from the user.
         * @param {CompanyRole} role 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCompanyRole(role, companyId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyRolesControllerApi.removeCompanyRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyRolesControllerApi - factory interface
 * @export
 */
export const CompanyRolesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyRolesControllerApiFp(configuration)
    return {
        /**
         * The company role for the specified company is being assigned to the user. Endpoint accessible to all Dataland-Admins and some Company-Role-Assignees of the company, based on the company role that shall be assigned.
         * @summary Assign company role for the company to the user.
         * @param {CompanyRole} role 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<CompanyRoleAssignment> {
            return localVarFp.assignCompanyRole(role, companyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get company role assignments that match the provided filters. Endpoint fully accessible to all Dataland-Admins. Company-Role-Assignees can access the endpoint if companyId-filter is set to their company. Any Dataland-user can access the endpoint if the userId-filter is set to their userId.
         * @summary Get company role assignments that match the provided filters.
         * @param {CompanyRole} [role] 
         * @param {string} [companyId] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyRoleAssignments(role?: CompanyRole, companyId?: string, userId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CompanyRoleAssignment>> {
            return localVarFp.getCompanyRoleAssignments(role, companyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Validates if at least one company owner exists for the specified company. Endpoint accessible to all Dataland users.
         * @summary Validate existence of company ownership for the company.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasCompanyAtLeastOneOwner(companyId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.hasCompanyAtLeastOneOwner(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Checks whether the company role for the company is assigned to the user. Endpoint accessible to all Dataland-Admins, for all users that check for their own userId, and all Company-Role-Assignees of the company.
         * @summary Validate company role for company and user.
         * @param {CompanyRole} role 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.hasUserCompanyRole(role, companyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Request company ownership for the company on Dataland. Endpoint accessible to all Dataland users.
         * @summary Request company ownership for the company.
         * @param {string} companyId 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOwnershipRequest(companyId: string, comment?: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postCompanyOwnershipRequest(companyId, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * The company role for the specified company is being removed from the user. Endpoint accessible to all Dataland-Admins and some Company-Role-Assignees of the company, based on the company role that shall be removed.
         * @summary Remove company role for the company from the user.
         * @param {CompanyRole} role 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeCompanyRole(role, companyId, userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyRolesControllerApi - interface
 * @export
 * @interface CompanyRolesControllerApi
 */
export interface CompanyRolesControllerApiInterface {
    /**
     * The company role for the specified company is being assigned to the user. Endpoint accessible to all Dataland-Admins and some Company-Role-Assignees of the company, based on the company role that shall be assigned.
     * @summary Assign company role for the company to the user.
     * @param {CompanyRole} role 
     * @param {string} companyId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApiInterface
     */
    assignCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<CompanyRoleAssignment>;

    /**
     * Get company role assignments that match the provided filters. Endpoint fully accessible to all Dataland-Admins. Company-Role-Assignees can access the endpoint if companyId-filter is set to their company. Any Dataland-user can access the endpoint if the userId-filter is set to their userId.
     * @summary Get company role assignments that match the provided filters.
     * @param {CompanyRole} [role] 
     * @param {string} [companyId] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApiInterface
     */
    getCompanyRoleAssignments(role?: CompanyRole, companyId?: string, userId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CompanyRoleAssignment>>;

    /**
     * Validates if at least one company owner exists for the specified company. Endpoint accessible to all Dataland users.
     * @summary Validate existence of company ownership for the company.
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApiInterface
     */
    hasCompanyAtLeastOneOwner(companyId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Checks whether the company role for the company is assigned to the user. Endpoint accessible to all Dataland-Admins, for all users that check for their own userId, and all Company-Role-Assignees of the company.
     * @summary Validate company role for company and user.
     * @param {CompanyRole} role 
     * @param {string} companyId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApiInterface
     */
    hasUserCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Request company ownership for the company on Dataland. Endpoint accessible to all Dataland users.
     * @summary Request company ownership for the company.
     * @param {string} companyId 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApiInterface
     */
    postCompanyOwnershipRequest(companyId: string, comment?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * The company role for the specified company is being removed from the user. Endpoint accessible to all Dataland-Admins and some Company-Role-Assignees of the company, based on the company role that shall be removed.
     * @summary Remove company role for the company from the user.
     * @param {CompanyRole} role 
     * @param {string} companyId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApiInterface
     */
    removeCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CompanyRolesControllerApi - object-oriented interface
 * @export
 * @class CompanyRolesControllerApi
 * @extends {BaseAPI}
 */
export class CompanyRolesControllerApi extends BaseAPI implements CompanyRolesControllerApiInterface {
    /**
     * The company role for the specified company is being assigned to the user. Endpoint accessible to all Dataland-Admins and some Company-Role-Assignees of the company, based on the company role that shall be assigned.
     * @summary Assign company role for the company to the user.
     * @param {CompanyRole} role 
     * @param {string} companyId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApi
     */
    public assignCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig) {
        return CompanyRolesControllerApiFp(this.configuration).assignCompanyRole(role, companyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get company role assignments that match the provided filters. Endpoint fully accessible to all Dataland-Admins. Company-Role-Assignees can access the endpoint if companyId-filter is set to their company. Any Dataland-user can access the endpoint if the userId-filter is set to their userId.
     * @summary Get company role assignments that match the provided filters.
     * @param {CompanyRole} [role] 
     * @param {string} [companyId] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApi
     */
    public getCompanyRoleAssignments(role?: CompanyRole, companyId?: string, userId?: string, options?: RawAxiosRequestConfig) {
        return CompanyRolesControllerApiFp(this.configuration).getCompanyRoleAssignments(role, companyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates if at least one company owner exists for the specified company. Endpoint accessible to all Dataland users.
     * @summary Validate existence of company ownership for the company.
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApi
     */
    public hasCompanyAtLeastOneOwner(companyId: string, options?: RawAxiosRequestConfig) {
        return CompanyRolesControllerApiFp(this.configuration).hasCompanyAtLeastOneOwner(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks whether the company role for the company is assigned to the user. Endpoint accessible to all Dataland-Admins, for all users that check for their own userId, and all Company-Role-Assignees of the company.
     * @summary Validate company role for company and user.
     * @param {CompanyRole} role 
     * @param {string} companyId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApi
     */
    public hasUserCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig) {
        return CompanyRolesControllerApiFp(this.configuration).hasUserCompanyRole(role, companyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request company ownership for the company on Dataland. Endpoint accessible to all Dataland users.
     * @summary Request company ownership for the company.
     * @param {string} companyId 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApi
     */
    public postCompanyOwnershipRequest(companyId: string, comment?: string, options?: RawAxiosRequestConfig) {
        return CompanyRolesControllerApiFp(this.configuration).postCompanyOwnershipRequest(companyId, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The company role for the specified company is being removed from the user. Endpoint accessible to all Dataland-Admins and some Company-Role-Assignees of the company, based on the company role that shall be removed.
     * @summary Remove company role for the company from the user.
     * @param {CompanyRole} role 
     * @param {string} companyId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRolesControllerApi
     */
    public removeCompanyRole(role: CompanyRole, companyId: string, userId: string, options?: RawAxiosRequestConfig) {
        return CompanyRolesControllerApiFp(this.configuration).removeCompanyRole(role, companyId, userId, options).then((request) => request(this.axios, this.basePath));
    }
}

